<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label for="">¿Cuantos sois?</label>
        <select v-model="playersSelected" @change="changeNumber">
            <option :value="player + minPlayers" v-for="player in players">
               {{ player + minPlayers}} PERSONAS
            </option>
        </select>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import Calendar from './../../calendar/calendar.vue'

    export default {
        props: ["experience"],
        name: "select-num-players",
        components: { Calendar },
        data() {
            return {
                minPlayers: 0,
                maxPlayers: 0,
                players: [],
                playersSelected: 0,
                itemsCheckout: null
            }
        },
        computed: {
            ...mapGetters(['getDesign','getNumPlayerItems','getCheckout'])
        },
        created() {
            this.minPlayers = this.experience.minPlayers;
            this.maxPlayers = this.experience.maxPlayers * (this.experience.calendars.length);
            this.playersSelected = this.getNumPlayerItems({id_experience: this.experience.id});
            this.players = Array.from(Array(this.maxPlayers + 1 - this.minPlayers).keys());
            this.changeNumber();
        },
        methods: {
            ...mapMutations(['updatePeopleNumber']),
            getPositionValue: function (value) {
                for (let i = 0; i < this.experience.prices.prices.length; i += 1) {
                    if (this.experience.prices.prices[i].people === value) {
                        return i;
                    }
                }
            },
            changeNumber: function () {
                this.$emit('change-field', this.playersSelected);
            }
        }
    }
</script>