<template>
    <div class="form-group form-group-checkbox" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label>{{ this.field.name }}</label>
        <input type="checkbox" v-model="value" :required="field.isRequired" @change="changeField">
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "checkbox",
        props: ["field","experience"],
        data() {
            return {
                value: '',
            }
        },
        created() {
            if(this.field.value) {
                this.value = this.field.value;
            }
            this.changeField();
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout'])
        },
        methods: {
            ...mapMutations(['updateItemField']),
            changeField: function () {

                let newField =  {
                    id: this.$uuid.v4(this.field.id),
                    name: this.field.name,
                    title: this.field.name,
                    value: this.value,
                    amount: 0
                };
                this.updateItemField({id_experience: this.experience, newField: newField});

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            },
        }
    }
</script>