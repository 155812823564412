<template>
    <div id="schedules">
        <div v-if="unifySchedules.length > 0" >
            <div class="schedules" v-if="displayMode == 'lista'">
                <ScheduleList
                  @schedule-selected="scheduleSelected"
                  :schedule="schedule"
                  :current-day="currentDay"
                  :experience-content="experience"
                  :current-day-plus="currentDayPlus"
                  v-for="(schedule, index) in unifySchedules" v-bind:key="index"/>
            </div>

            <div class="schedules" v-else>
                <ScheduleCompact
                  @schedule-selected="scheduleSelected"
                  :schedule="schedule"
                  :number-players="numberPlayers"
                  :current-day="currentDay"
                  :experience-content="experience"
                  :current-day-plus="currentDayPlus"
                  v-for="(schedule, index) in unifySchedules"  v-bind:key="index"/>
            </div>
        </div>
        <p v-else>Sin sesiones disponibles</p>
    </div>
</template>

<script>
    import api from '../../api';

    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import ItemCheckout from './../../classes/ItemCheckOut';
    import ScheduleCompact from './schedule/schedule-compact/schedule-compact.vue';
    import ScheduleList from './schedule/schedule-list/schedule-list.vue';

    export default {
        props: ["numberPlayers","experience","displayMode"],
        name: "schedules",
        extends: api,
        components: {
            ScheduleCompact,
            ScheduleList
        },
        data() {
            return {
                experienceUrl: '',
                currentDay: null,
                currentDayPlus: null,
                unifySchedules: []
            }
        },
        async created() {
            this.currentDay = new Date();
            this.currentDayPlus = new Date(this.currentDay.getTime() + this.experience.TTC*60000);
            this.experienceUrl = this.getIdsLink()[this.experience.id];

            await this.getSchedulesExperience();
        },
        computed: {
            ...mapGetters(['getCheckout','getIdsLink','getDateSelected','getExperiences']),
        },
        methods: {
            ...mapMutations(['updateExperiences', 'changeItem','addItem']),
            unifySchedulesMethod: function (experiences) {
                let finalArray = [];
                experiences[0].calendars.forEach((calendar) => {
                    calendar.schedules.forEach((schedule) => {
                        if(schedule.isFree) {
                            let objectTemp = {
                                'end_session' : schedule.end_session,
                                'id_calendar' : calendar.id,
                                'isFree' : true,
                                'start_session' : schedule.start_session,
                            };
                            finalArray.push(objectTemp);
                        }
                    })
                });
                this.unifySchedules = finalArray.filter((tag, index, array) => array.findIndex(t => t.start_session == tag.start_session && t.end_session == tag.end_session) == index);

              function compare( a, b ) {
                if ( a.start_session < b.start_session ){
                  return -1;
                }
                if ( a.start_session > b.start_session ){
                  return 1;
                }
                return 0;
              }
              this.unifySchedules.sort( compare );

              if(this.unifySchedules.length > 0){
                this.$emit('schedule-loaded');
              }
            },
            getSchedulesExperience: async function () {
                let experiencePrommise = new Promise((resolve) => {
                    this.getExperiencesByIdApi(resolve);
                });
                experiencePrommise.then((experiences) => {
                    this.updateExperiences({experiences: experiences});
                    this.unifySchedulesMethod(experiences);
                });
            },
            scheduleSelected: function (params) {

                let newFormatDateSplit = this.getDateSelected().split('/');
                let newDate = newFormatDateSplit[2] + '-' + newFormatDateSplit[1].padStart(2, "0") + '-' + newFormatDateSplit[0];
                let numberOfCalendar = Math.ceil(this.numberPlayers/this.experience.maxPlayers);

                let first = true;

                for (let i = 0; i < this.experience.calendars.length; i++) {
                  if(this.numberPlayers > 0){
                    /* Check is this calendar has free sesion for this hours */
                    let calendarCheck = this.experience.calendars[i].schedules.filter(schedule => schedule.start_session.includes(params.start_session))[0];
                    //If not, continue
                    if(calendarCheck.isFree) {
                      //Else, we take it
                      let calendar = this.experience.calendars[i];
                      /* If number players selected is bigger than maxNumber of calendar, we introduce the max number, else we set the number players selected by User */
                      let numberPlayersInTheCalendar = this.numberPlayers > this.experience.maxPlayers ? this.experience.maxPlayers : this.numberPlayers;
                      if(first) {
                        let itemCheckout = new ItemCheckout(
                            this.experience.id,
                            calendar.id,
                            newDate + ' ' + params.start_session,
                            newDate + ' ' + params.end_session,
                            numberPlayersInTheCalendar,
                            this.getTotalPriceCalcutate(),
                            // this.experience.prices.prices[0].full_price,
                            this.experience.prices.partial_price
                        );
                        this.changeItem({item: itemCheckout});
                        first = false;
                      } else {
                        let itemCheckout = new ItemCheckout(
                            this.experience.id,
                            calendar.id,
                            newDate + ' ' + params.start_session,
                            newDate + ' ' + params.end_session,
                            numberPlayersInTheCalendar,
                            //this.experience.prices.prices[0].full_price,
                            0,
                            this.experience.prices.partial_price
                        );
                        this.addItem({item: itemCheckout});
                      }
                      /* When we insert or add the reserve, we rest the number of players that we reserve */
                      this.numberPlayers -= numberPlayersInTheCalendar;
                    }
                    numberOfCalendar--;
                  }
                }
                this.$emit('schedule-selected');
            },
            getTotalPriceCalcutate: function () {
              let total = 0;
              this.getCheckout().items.map((item) => {
                let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                total = (experienceSelected.prices.prices.find(price => price.people === this.numberPlayers))? (experienceSelected.prices.prices.find(price => price.people === this.numberPlayers).full_price * this.numberPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * this.numberPlayers);
              });
              return parseFloat(total).toFixed(2);
            },
        }
    }
</script>

<style lang="scss" scoped>
    #schedules {
        .schedules {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>