<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label for="paymentMethod">Método de pago</label>
        <select v-model="paymentMethodSelected" name="paymentMethod" id="paymentMethod" @change="changePaymentMethod">
            <option v-for="(paymentMethod,index) in paymentMethods" :value="index">{{ paymentMethod.name }}</option>
        </select>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';

    import api from '@/api';

    export default {
        extends: api,
        name: "payment-methods",
        props: ["callSunday"],
        computed: {
            ...mapGetters(['getDesign','getPaymentMethods','getDateSelected']),
        },
        data() {
            return {
                paymentMethodSelected: 0,
                paymentMethods: []
            }
        },
        created() {
            this.getPaymentMethodsCall();
            // console.log('Dia seleccionado');
            // console.log(this.getDateSelected())
        },
        methods: {
            getPaymentMethodsCall: async function () {
              this.getPaymentMethods().split(',').map((payment) => {
                    let paymentPromise = new Promise((resolve) => {
                        this.getPaymentMethodsApi(payment, resolve);
                    });
                    paymentPromise.then((payment) => {

                        /* Si tenemos callSundays activado, el día seleccionado es domingo -> solo vamos a meter en el array in_local_payment*/
                        let dateSelected = this.getDateSelected().split('/');
                        let dateSelectedDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], 0);

                        /* Si tenemos callSundays activado */
                        if(this.$props.callSunday) {
                            /* Si el día seleccionado es Domingo, */
                            if(dateSelectedDate.getDay() === 0) {
                                /* Solo añadimos al array in_local_payment*/
                                if(payment[0].type == 'in_local_payment') {
                                    this.paymentMethods  = [];
                                    this.paymentMethods.push(payment[0]);
                                }
                            }
                            /* Si no es Domingo, añadimos al array todos menos in_local_payment*/
                            else if(payment[0].type != 'in_local_payment') {
                                this.paymentMethods.push(payment[0]);
                            }
                        }
                        else {
                            this.paymentMethods.push(payment[0]);
                        }
                        this.changePaymentMethod();
                    });
                });
            },
            changePaymentMethod: function () {
                if(this.getCheckout().id) {
                    this.$emit('change-payment-method', {paymentMethodId: this.paymentMethods[this.paymentMethodSelected].id});
                }
            },
        }
    }
</script>