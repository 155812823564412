<template>
    <div v-if="!checkPassDay() && checkIsAvaliable()" v-bind:class="checkSchedule" class="schedule-compact">
        <a v-if="(checkDelay()) && scheduleMobile" class="schedule-content" :href="'tel:' + this.experienceContent.phone">
            <div class="schedule-image">
                <img v-if="this.getDesign() == 'black'" src="./../assets/phone_dark.png" alt="">
                <img v-else src="./../assets/phone_white.png" alt="">
            </div>
            <span>{{ this.schedule.start_session.slice(0, -3) }}</span>
        </a>
        <div v-else-if="(checkDelay()) && scheduleDesktop" class="schedule-content" @click="openModal = !openModal">
            <div class="schedule-image">
                <img v-if="this.getDesign() == 'black'" src="./../assets/phone_dark.png" alt="">
                <img v-else src="./../assets/phone_white.png" alt="">
            </div>
            <span>{{ this.schedule.start_session.slice(0, -3) }}</span>
        </div>
        <div class="schedule-content" @click="selectSchedule()" v-else>
            <div class="schedule-image">
                <img v-if="this.getDesign() == 'black'" src="./../assets/clock_dark.png" alt="">
                <img v-else src="./../assets/clock_white.png" alt="">
            </div>
            <span>{{ this.schedule.start_session.slice(0, -3) }}</span>
        </div>

        <div v-if="openModal" class="modal-call" @click="openModal = !openModal">
            <div class="modal-call-center">
                {{ $t('callTo') }} {{ this.experienceContent.phone }} {{ $t('callTo2') }}
            </div>
        </div>
    </div>
</template>

<script>

    import schedule from './../schedule';

    export default {
        extends: schedule,
        name: 'schedule-compact'
    };

</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "schedule-compact";
</style>