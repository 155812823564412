import Vue from 'vue'
import Vuex from 'vuex'
import Checkout from './../classes/Checkout';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: null,
        design: '',
        minHeight: 0,
        playersSelected: 0,
        idPurchase: null,
        ids: null,
        experiences: [],
        paymentMethods: null,
        idsLink: null,
        multiCalendar: false,
        dateSelected: null,
        checkout: null
    },
    getters: {
        getToken: (state) => () => {
            return state.token;
        },
        getIdPurchase: (state) => () => {
            return state.idPurchase;
        },
        getIdsLink: (state) => () => {
            return state.idsLink;
        },
        getIds: (state) => () => {
            return state.ids;
        },
        getDateSelected: (state) => () => {
            return state.dateSelected;
        },
        getPaymentMethods: (state) => () => {
            return state.paymentMethods;
        },
        getMultiCalendar: (state) => () => {
            return state.multiCalendar;
        },
        getState: (state) => () => {
            return state;
        },
        getCheckout: (state) => () => {
            return state.checkout
        },
        getExperiences: (state) => () => {
          return state.experiences
        },
        getDesign: (state) => () => {
            return state.design;
        },
        getMinHeight: (state) => () => {
            return state.minHeight;
        },
        getFieldItems: (state) => (payload) => {
            let fieldFinal = null;
            state.checkout.items.map((item) => {
                if(item.id_experience === payload.id_experience) {
                    item.fields.map((filter) => {
                        if(filter.title === payload.title) {
                            fieldFinal = filter;
                        }
                    });
                }
            });

            return fieldFinal;
        },
        getNumPlayerItems: (state) => (payload) => {
            var number = 0;
            state.checkout.items.map((item) => {
                if(item.id_experience == payload.id_experience) {
                    //console.log('entra')
                   number += parseInt(item.people_number);
                }
            });

            return number;
        },
        getExperiencesCheckout: (state) => () => {

            let experiences_final = [];
            state.checkout.items.map((item) => {
                let experience = state.experiences.filter(experience => experience.id === item.id_experience);
                experiences_final.push(experience[0]);
            });

            return experiences_final;
        }
    },
    mutations: {
        refreshState: (state) => {
            state.checkout.id = null;
            state.checkout.items = [];
            state.checkout.code = null;
            state.multiCalendar = false;
        },
        updateMinHeight: (state, payload) => {
            state.minHeight  = payload.minHeight;
        },
        updateToken: (state, payload) => {
            state.token = payload.token;
        },
        updateIdPurchase: (state,payload) => {
            state.idPurchase = payload.idPurchase;
        },
        updatePaymentsMethods: (state,payload) => {
            state.paymentMethods = payload.paymentMethods;
        },
        updateIdsLinks: (state, payload) => {
            state.idsLink = JSON.parse(payload.idsLink);
        },
        updateDesign: (state,payload) => {
            state.design = payload.design;
        },
        updateIds: (state, payload) => {
            state.ids = payload.ids;
        },
        updateExperiences: (state, payload) => {
            state.experiences = payload.experiences;
        },
        updateDateSelected: (state, payload) => {
            state.dateSelected = payload.dateSelected;
            state.multiCalendar = true;
        },
        createDateSelected: (state, payload) => {
            state.dateSelected = payload.dateSelected;
            state.multiCalendar = true;
            if(payload.notUpdateCheckout === undefined) {
                state.checkout = new Checkout();
            }
        },
        udpateMultiCalendar: (state) => {
            state.multiCalendar = !state.multiCalendar;
            state.checkout = new Checkout();
        },
        changeItem: (state,payload) => {
            state.checkout.items = [payload.item];
        },
        addItem: (state, payload) => {
            state.checkout.items.push(payload.item);
        },
        removeItem: (state) => {
            let item = state.checkout.items[0];
            state.checkout.items = [item];
            state.checkout.items.map((item) => {
                item.start = null;
                item.end = null;
            });
        },
        updateCheckout: (state, payload) => {
            let code = '';
            if(state.checkout) {
                code = state.checkout.code;
            }
            state.checkout = payload.checkout;
            state.checkout.code = code;
        },
        updateCheckoutStatus: (state, payload) => {
            state.status = payload.status;
        },
        updateCheckoutPartial: (state, payload) => {
            state.checkout.is_fractioned = payload.is_fractioned;
        },
        updateCheckoutField: (state, payload) => {
            state.checkout[payload.name] = payload.value;
        },
        updatePeopleNumber: (state, payload) => {
            state.checkout.items.map((item) => {
                if(item.id_experience === payload.id_experience && item.id_calendario === payload.id_calendario && item.start === payload.start) {
                    item.people_number = payload.people;
                    item.price_original = payload.price;
                }
            })
        },
        updateItemField: (state, payload) => {
            state.checkout.items.map((item) => {
                if(item.id_experience === payload.id_experience) {
                    let find = false;
                    item.fields.map((filter) => {
                        if(filter.title === payload.newField.title) {
                            find = true;
                            filter.value = payload.newField.value;
                            filter.name = payload.newField.name;
                            filter.amount = payload.newField.amount;
                        }
                    });
                    if(!find) {
                        item.fields.push(payload.newField);
                    }
                }
            })
        }
    },
    actions: {},
    modules: {}
})
