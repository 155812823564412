import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    data() {
        return {
            experience: null,
        }
    },
    created() {
        this.experience = this.getExperiencesCheckout()[0];
    },
    computed: {
        ...mapGetters(['getToken','getDesign','getCheckout','getExperiences','getExperiencesCheckout','getDateSelected']),
        getItemsExperience: function () {
            return (id_experience) => {
                let finalItems = this.getCheckout().items.filter((item) => {
                    return item.id_experience === id_experience;
                });
                return finalItems;
            }
        }
    },
    methods: {
        ...mapMutations(['updateItemField','updateCheckout','updateCheckoutField']),
        changeField: function () {
            if(this.getCheckout().id) {
                let updatePurchasePrommise = new Promise((resolve) => {
                    this.updatePurchaseApi(resolve);
                });
                updatePurchasePrommise.then((purchase) => {
                    this.updateCheckout({checkout: purchase});
                });
            }
        }
    }
}