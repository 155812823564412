import { mapGetters } from 'vuex';

import { changeDay } from '@/main';
import api from '@/api';

export default {
    extends: api,
    props: ["blockView","numberPlayers","experience"],
    data() {
        return {
            gaps: 0,
            notFound: false,
            currentMonth: 0,
            currentDay : 0,
            lastDay: 31,
            currentYear: 0,
            dates: []
        }
    },
    computed: {
        ...mapGetters(['getIds','getToken','getDesign','getDateSelected']),
        showArrowLeft : function () {
            let date = new Date();
            let month = date.getMonth()+1;
            let year = date.getFullYear();

            return !(this.currentMonth === month && this.currentYear === year);
        },
        monthName: function () {
            switch (this.currentMonth) {
                case 1: return 'Ene';
                case 2: return 'Feb';
                case 3: return 'Mar';
                case 4: return 'Abr';
                case 5: return 'May';
                case 6: return 'Jun';
                case 7: return 'Jul';
                case 8: return 'Ago';
                case 9: return 'Sep';
                case 10: return 'Oct';
                case 11: return 'Nov';
                case 12: return 'Dic';
            }
        },
        dayObject: function () {

            return (day,enabled) => {

                let dayInDates = this.getDateFromDay(day);

                if(dayInDates && enabled) {
                    let checkLow = (dayInDates.occupied/dayInDates.total)*100 > 20;
                    let block = dayInDates.occupied >= dayInDates.total;

                    return {
                        'current' : day == this.currentDay,
                        'enabled' : dayInDates.occupied === 0 && dayInDates.total > this.numberPlayers || !checkLow,
                        'low': checkLow,
                        'block' : block
                    }
                } else {
                    return {
                        'current': (day === this.currentDay) && !this.notFound,
                    }
                }

            };
        },
        checkDateBefore : function () {
            return(dayInCalendar) => {

                let dayInDates = this.getDateFromDay(dayInCalendar);

                let date = new Date();
                let month = date.getMonth()+1;
                let day = date.getDate();
                let year = date.getFullYear();

                if(dayInDates) {
                    if((dayInCalendar >= day) || this.currentMonth > month || this.currentYear > year) {
                        return dayInDates.total > 0;
                    }
                    else return false;
                }
                else {
                    return false;
                }
            }
        },
        checkDayBlockedView: function () {
            return(day) => {
                let dayInDates = this.getDateFromDay(day);
                if(dayInDates) {
                    let block = dayInDates.blocked >= dayInDates.total;
                    return !block;
                }
                else {
                    return false;
                }
            }
        }
    },
    created() {
        this.getCalendar(false);
    },
    methods: {
        nextMonth: function () {
            if(this.currentMonth === 12) {
                this.currentMonth = 1;
                this.currentYear++;
            }
            else this.currentMonth++;

            this.currentDay = 1;
            this.getCalendar(true);
        },
        previusMonth: function () {
            if(this.currentMonth === 1){
                this.currentMonth = 12;
                this.currentYear--;
            }
            else this.currentMonth--;

            this.currentDay = 1;
            this.getCalendar(true);
        },
        getDateFromDay: function (day) {

            let dayComplete = day < 10 ? ('0' + day) : day;
            let monthComplete = this.currentMonth < 10 ? ('0' + this.currentMonth) : this.currentMonth;
            let dateComplete = dayComplete + '/' + monthComplete + '/' + this.currentYear;
            return this.dates[dateComplete];
        },
        getCalendar: function (month) {

            if(!month) {
                let currentDate = this.getDateSelected();
                let currentDateArray = currentDate.split('/');
                this.currentDay = parseInt(currentDateArray[0]);
                this.currentMonth = parseInt(currentDateArray[1]);
                this.currentYear = parseInt(currentDateArray[2]);
            }

            let firstDay = new Date(this.currentYear, this.currentMonth - 1, 1);
            let lastDay = new Date(this.currentYear, this.currentMonth, 0);

            this.gaps = firstDay.getDay()-1;

            if(this.gaps === -1) this.gaps = 6;

            this.lastDay =  lastDay.getDate();

            let firstDayFormated = firstDay.getDate() + '/' + this.currentMonth + '/' +  this.currentYear;
            let lastDayFormated = lastDay.getDate() + '/' + this.currentMonth + '/' +  this.currentYear;

            let currentDateFinal = this.currentDay + '/' + this.currentMonth + '/' +  this.currentYear;

            this.callGetCalendarIds(firstDayFormated, lastDayFormated, currentDateFinal, month);

        },
        callGetCalendarIds : function (fromDate,toDate, currentDate, month) {
            let datePrommise = new Promise((resolve) => {
                this.getDatesApi(fromDate,toDate, resolve);
            });
            datePrommise.then((dates) => {
                this.dates = dates;
                if(month) {
                    let notFoundOther = false;
                    for (let day = 0; day <= this.lastDay; day++) {
                        let objectDay = this.dayObject(day,true);
                        if(objectDay.enabled && !objectDay.block) {
                            let currentDate = day + '/' + this.currentMonth + '/' +  this.currentYear;
                            this.currentDay = day;

                            this.$emit('date-selected', currentDate);
                            this.$emit('date-selected-notfound', false);
                            this.notFound = true;
                            notFoundOther = true;
                            break;
                        }
                    }
                    if(!notFoundOther) {
                        let currentDate = '1/' + this.currentMonth + '/' +  this.currentYear;
                        this.currentDay = 1;
                        this.$emit('date-selected', currentDate);
                        this.$emit('date-selected-notfound', true);
                    }
                }
                else {
                    this.$emit('date-selected', currentDate);
                    this.$emit('date-selected-notfound', false);
                }

            });
        },
        selectDay: function (day) {

            this.currentDay = day;
            let dateSelected = this.currentDay + '/' + this.currentMonth + '/' +  this.currentYear;

            if(document.getElementById("step-one")) document.getElementById("step-one").scrollIntoView();

            this.$emit('date-selected-click', dateSelected);
        },
    }
}