import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    props: ["terms","callSunday"],
    data() {
        return {
            showCheckoutStepOne: true,
            loading: true,
            showCalendar: false,
            showPaymentsForm: false,
            checkedConditions: false,
            showStepOne: false,
            stepOneKey: 0,
            paymentMethodId: 1,
            paymentMethod: null,
            showStepTwo: false,
            showFormsPayments: false,
            stripeKey: 0,
            resumeKey: 0
        }
    },
    created() {
        window.onbeforeunload = function () {
            return '';
        };
        window.visibilitychange = function () {
            return '';
        };
        //this.createNewPurchase();
    },
    computed: {
        ...mapGetters(['getDateSelected','getCheckout','getExperiences','getDesign','getToken']),
        roomHour: function () {
            return (room) => {
                return room.start.split(' ')[1].slice(0, -3);
            }
        },
        totalPeople: function () {
            let people = 0;
            this.getCheckout().items.forEach((item) => { people += item.people_number });
            return people;
        },
        getTotalPriceCalcutate: function () {
            let total = 0;
            let totalPlayers = 0;
            this.getCheckout().items.map((item) => {
                totalPlayers += item.people_number;
                let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
            });
            return parseFloat(total).toFixed(2);
        },

    },
    methods: {
        ...mapMutations(['updateIdPurchase','updateCheckout','refreshState']),
        createNewPurchase: function () {
            if(this.getCheckout().id == null) {
                let createPurchasePrommise = new Promise((resolve) => {
                    this.createPurchaseApi(resolve);
                });
                createPurchasePrommise.then((purchase) => {
                    this.loading = true;
                    this.resumeKey++;
                    this.updateIdPurchase({idPurchase: purchase.id});
                    this.updateCheckout({checkout: purchase});

                    //document.getElementById("step-two").scrollIntoView();
                });
            }
        },
        changePaymentMethod: function (value) {
            this.paymentMethod = value.payment;
            this.paymentMethodId = value.paymentMethodId
        },
        nextStep: function () {
            const formGroups = Array.from(document.getElementsByClassName("required-field"));
            const requiredFields = formGroups.reduce((fields, group) => {
                const input = group.getElementsByTagName("input");
                if (input[0].hasAttribute("required")) {
                    fields.push(input[0]);
                }
                return fields;
            }, []);

            let isValid = true;
            requiredFields.forEach(field => {
                if (!field.value) {
                    isValid = false;
                    field.style.border= "solid 2px red";
                    field.scrollIntoView();
                }
            });
            if (isValid) {
                this.showCheckoutStepOne = false;
            }
        },
        backButton: function () {
            if(this.showCheckoutStepOne) this.$emit('back-step');
            else this.showCheckoutStepOne = true;
        },
        checkForm: function (e) {

            e.preventDefault();

            if(this.getCheckout().id) {
                this.showFormsPayments = true;

                if (this.paymentMethod.url) {
                    window.location.href = this.paymentMethod.url;

                    this.saveLocalStoragePayment();
                }
                else if (this.paymentMethod.form && this.paymentMethodId !== 3) {
                    setTimeout(() => {
                        let form = document.getElementById('final-form');
                        form.action = this.paymentMethod.form.action;
                        form.method = 'post';
                        for (let [key, value] of Object.entries(this.paymentMethod.form)) {
                            if (key !== 'action') {
                                form.innerHTML += '<input name="' + key + '" value="' + value + '" />';
                            }
                        }
                        this.saveLocalStoragePayment();
                        form.submit();
                    }, 500);
                }
                else {
                    this.stripeKey++;
                }
            }
        },
        saveLocalStoragePayment: function () {
            localStorage.setItem('toPayment', this.getCheckout().id);
            localStorage.setItem('token', this.getToken());
        }
    }
}