import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    props: ["schedule","currentDay","currentDayPlus","experienceContent", "numberPlayers"],
    data() {
        return {
            scheduleSelected: false,
            scheduleDesktop: false,
            scheduleMobile: false,
            openModal: false,
        }
    },
    computed: {
        ...mapGetters(['getCheckout','getDesign','getDateSelected']),
        checkSchedule : function () {

            let checkDelayResponse = this.checkDelay();
            let checkPassDay = this.checkPassDay();
            let checkCallSunday = false;

            if(checkPassDay) {
                return {
                    'selected' : false,
                    'call': false,
                    'free': false,
                    'block' : true,
                    'white' : true
                }
            }
            else {
                return {
                    'selected' : this.scheduleSelected,
                    'call': checkDelayResponse || checkCallSunday,
                    'free': this.schedule.isFree,
                    'block' : !this.schedule.isFree,
                    'white' : true
                }
            }
        },
    },
    created() {
        this.checkDevice();
    },
    methods: {
        ...mapMutations(['addItem','removeItem']),
        checkDevice: function () {
            const toMatch = [
                /Android/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            this.scheduleMobile = toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });

            this.scheduleDesktop = !this.scheduleMobile;
        },
        checkDelay: function () {
            if(this.schedule.isFree) {
                let startSession = this.schedule.start_session.split(':');
                let dateSelected = this.getDateSelected().split('/');
                let roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

                return this.currentDay.getTime() <= roomDate.getTime() &&  this.currentDayPlus.getTime() >= roomDate.getTime();
            }
            else {
                return false;
            }
        },
        checkPassDay: function () {

            let startSession = this.schedule.start_session.split(':');
            let dateSelected = this.getDateSelected().split('/');
            let roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

            return roomDate.getTime() < this.currentDay.getTime();
        },

        checkIsAvaliable: function () {

            let numberCalendarsForSchedule = 0;
            this.experienceContent.calendars.forEach((calendar) => {
                calendar.schedules.forEach((itemSchedule) => {
                    if(itemSchedule.start_session === this.schedule.start_session && itemSchedule.isFree) numberCalendarsForSchedule++;
                })
            });
            let maxNumberPeopleAcceptForSchedule = numberCalendarsForSchedule * this.experienceContent.maxPlayers;

            return maxNumberPeopleAcceptForSchedule >= this.numberPlayers;
        },

        selectSchedule: function () {
            this.$emit('schedule-selected',{start_session: this.schedule.start_session, end_session: this.schedule.end_session,  id_calendar: this.schedule.id_calendar});
        }
    }
}