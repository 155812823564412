<template>
    <div v-if="experiencies.length > 0" id="step-one-box-inner">
        <Experience v-for="(experience,key) in experiencies"
                    :key = key
                    :show-experience-name="showExperienceName"
                    :call-sunday="callSunday"
                    :display-mode="displayMode"
                    :show-room-name="showRoomName"
                    :experience-content=experience
                    :more-info="moreInfo"
                    :multi-booking="multiBooking"
                    :url-experience="urlExperience"
                    @nex-step="$emit('nex-step')"
        />

    </div>
</template>

<script>

    import api from '@/api';

    import Experience from './single/experience.vue'


    import { mapMutations } from 'vuex';

    export default {
        extends: api,
        name: "experiences-small",
        props: ["moreInfo","displayMode","urlExperience","multiBooking","showExperienceName","showRoomName","callSunday"],
        components: {
            Experience
        },
        data() {
            return {
                experiencies: []
            }
        },
        created() {
            this.getExperiencies();
        },
        methods: {
            ...mapMutations(['updateExperiences']),
            getExperiencies: function () {
                let experiencePrommise = new Promise((resolve) => {
                    this.getExperiencesByIdApi(resolve);
                });
                experiencePrommise.then((experiences) => {

                    this.experiencies = experiences;
                    this.noExperience = experiences.length === 0;

                    this.updateExperiences({experiences: experiences});
                });
            }
        }
    }
</script>