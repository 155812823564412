<template>
    <div id="experience-form" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div class="form-group" v-for="field in experience.fields">
            <select-language v-if="field.isActive && field.isLanguage" :field="field" @change-field="changeField"> </select-language>
        </div>
        <div class="form-group-order">
            <div v-for="field in experience.fields" v-bind:class="{
                'input-form-group' : field.type === 'input',
                'select-form-group' : field.type === 'select',
                'textarea-form-group' : field.type === 'textarea',
                'checkbox-form-group' : field.type === 'checkbox'}">
                <input-normal v-if="field.type === 'input' && field.isActive && !field.isBasic"
                              :experience="experience.id"  :field="field"  @change-field="changeField"> </input-normal>
                <select-normal v-if="field.type === 'select' && field.isActive && !field.isBasic && !field.isLanguage"
                               :experience="experience.id" :field="field" @change-field="changeField"> </select-normal>
                <checkbox-normal v-if="field.type === 'checkbox' && field.isActive && !field.isBasic"
                                 :experience="experience.id" :field="field"  @change-field="changeField"> </checkbox-normal>
                <text-area-normal v-if="field.type === 'textarea' && field.isActive && !field.isBasic"
                                  :experience="experience.id" :field="field" @change-field="changeField"> </text-area-normal>
            </div>
        </div>
    </div>

</template>

<script>


    import SelectLanguage from './input-components/select-language'
    import SelectNormal from './input-components/select-normal'
    import InputNormal from './input-components/input-normal'
    import TextAreaNormal from './input-components/textarea-normal'
    import CheckboxNormal from './input-components/checkbox-normal'

    import FormGeneral from './../form-general/form-general.vue';

    import formExperience from './form-experience';

    export default {
        extends: formExperience,
        name: "form-experience",
        components: {
            SelectLanguage,
            SelectNormal,
            InputNormal,
            TextAreaNormal,
            CheckboxNormal,
            FormGeneral
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

   @import "form-experience";
</style>