<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label>{{ this.field.name }} <span v-if="field.isRequired">*</span></label>
        <select v-model="idSelectedField" :required="field.isRequired" @change="changeField">
            <option v-if="field.isRequired" selected> </option>
            <option :value="index" v-for="(option, index) in this.field.options">{{ option.name }}</option>
        </select>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "select",
        props: ["field","experience"],
        data() {
            return {
                idSelectedField: null,
            }
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout','getFieldItems'])
        },
        created() {

            let fieldTemp = this.getFieldItems({id_experience: this.experience, title: this.field.name});
            if(fieldTemp) {
                this.idSelectedField = this.getPositionValue(fieldTemp.value);
            }
            else if (!this.field.isRequired) {
                this.idSelectedField = 0;
            }
            this.changeField();
        },

        methods: {
            ...mapMutations(['updateItemField']),
            getPositionValue: function (value) {
                for (let i = 0; i < this.field.options.length; i += 1) {
                    if (this.field.options[i].value === value) {
                        return i;
                    }
                }
            },
            changeField: function () {

                let parameters = {value: null, name: this.field.name, price: 0};
                if(this.idSelectedField !== null) parameters = this.field.options[this.idSelectedField];


                let newField =  {
                    id: this.$uuid.v4(this.field.id),
                    name: parameters.name,
                    title: this.field.name,
                    value: parameters.value ? parameters.value : '',
                    amount: parameters.price
                };

                this.updateItemField({id_experience: this.experience, newField: newField});

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            }
        }
    }
</script>