<template>
    <div id="step-two" v-if="loading" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <form @submit="checkForm" >
            <header-experience @back-button="backButton"/>
            <div v-if="showCheckoutStepOne">
                <div class="box-checkout-content">
                    <form-experience />
                    <form-general />
                    <div id="general-conditions-content">
                        <div class="form-group form-group-checkbox"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
                            <label for="conditions_check">{{ $t('readTerms') }} <a target="_blank" :href="terms">{{ $t('readTerms2') }}</a></label>
                            <input id="conditions_check" name="conditions_check" type="checkbox" @click="createNewPurchase" v-model="checkedConditions" required>
                        </div>
                    </div>
                    <div id="resume-step-one-checkout">
                        <div class="left-part">
                            <p>Fecha: <span>{{ getDateSelected() }}</span></p>
                            <p>Hora: <span>{{ roomHour(getCheckout().items[0]) }}</span></p>
                            <p>Personas: <span>{{ totalPeople }}</span></p>
                        </div>
                        <div class="right-part">
                            <p @click="$emit('back-step')">Modificar</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <resume :key="resumeKey" :call-sunday="callSunday" @change-payment-method="changePaymentMethod"/>
            </div>
        </form>
        <div class="form-group-price" v-if="showCheckoutStepOne">
            <div class="price-number">
                Precio:
                <span>{{ getTotalPriceCalcutate }}€</span>
            </div>
            <div class="button button-flat"
                 v-if="getCheckout().items[0].start && checkedConditions"
                 @click="nextStep">Siguiente</div>
        </div>

        <div v-if="showFormsPayments">
            <stripe-component :key="stripeKey" :paymentMethod="paymentMethod" v-if="paymentMethodId === 3"/>
            <form v-if="paymentMethod" style="display: none;" id="final-form"> </form>
        </div>
    </div>
</template>

<script>
    import FormExperience from './form-experience/form-experience.vue';
    import FormGeneral from './form-general/form-general.vue';
    import Resume from './resume/resume.vue';
    import StripeComponent from './stripe-component/stripe-component.vue'
    import HeaderExperience from './header-experience/header-experience.vue'

    import checkout from './checkout';

    export default {
        extends: checkout,
        name: 'checkout',
        components: {
            FormExperience,
            FormGeneral,
            Resume,
            StripeComponent,
            HeaderExperience
        }
    };
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "checkout";
</style>