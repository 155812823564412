<template>
    <div id="calendar-selector"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
      <div class="back-button" onclick="window.location.href=window.location">Volver</div>
        <div class="experience-form" v-if="getItemsExperience(experience.id).length > 0" v-for="experience in getExperiences()">
            <div v-bind:style="{ background: 'url(' + experience.image + ') no-repeat center center' }" class="form-group-header">
                <p class="experience-name">{{ experience.name }}</p>
            </div>
            <div class="form-group-more-info-button">
                <MoreInfo v-if="experience.description.length > 0"
                           :title="$t('informationButton')"
                           :description="'<p>' + experience.description + '</p>'" />
            </div>

            <div class="form-group-content" v-bind:class="{'not-selected' : getTotalPriceCalcutate <= 0}">
                <div class="form-group">
                    <SelectNumPlayers :experience="experience"
                                      @change-field="changeField" />
                </div>
                <div class="form-group">
                    <DateSelected :key="keyDateSelected"
                                  :display-mode="displayMode"
                                  :keySchedule="keySchedule"
                                  :number-players="numberPlayers"
                                  :experience="experience"
                                  :block-view="blockView"
                                  @next-step="nextStep"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    import MoreInfo from './../mini-components/more-info.vue';
    import SelectNumPlayers from './input-components/SelectNumPlayers.vue';
    import DateSelected from './input-components/DateSelected.vue';

    export default {
        props: ["blockView","displayMode"],
        name: "calendar-selector",
        components: {
            MoreInfo,
            SelectNumPlayers,
            DateSelected
        },
        data() {
            return {
                keyDateSelected: 1,
                keySchedule: 1,
                numberPlayers: 0,
            }
        },
        computed: {
            ...mapGetters(['getCheckout','getExperiences','getDateSelected','getDesign']),
            getItemsExperience: function () {
                return (id_experience) => {
                    return this.getCheckout().items.filter((item) => {

                        return item.id_experience === id_experience;
                    })
                }
            },
            getTotalPriceCalcutate: function () {
                let total = 0;
                let totalPlayers = 0;
                this.getCheckout().items.map((item) => {
                    if(item.start) {
                        totalPlayers += item.people_number;
                        let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                        total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                    }

                });
                return parseFloat(total).toFixed(2);
            },
        },
        methods: {
            ...mapMutations(['removeItem']),
            nextStep: function () {
                this.$emit('nex-step')
            },
            changeField: function (numberPlayers) {

                this.removeItem();

                this.numberPlayers = numberPlayers;
                this.keySchedule++;
                this.keyDateSelected++;


            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #calendar-selector {
      .back-button {
        margin-bottom: 15px;
        text-transform: uppercase;
        color: $yellow-color;
        cursor: pointer;
      }
        .experience-form {
            .form-group-header {
                width: 100%;
                height: 130px;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                background-size: cover !important;
                border-radius: 20px;
                .experience-name {
                    color: #fff;
                    font-size: 35px;
                    line-height: 38px;
                    text-transform: uppercase;
                    margin: 20px 0 0 20px;
                    text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
                }
            }
            .form-group-content {
                margin-top: 20px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                padding: 30px;
                &.not-selected {
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
        &.dark {
            .experience-form {
                .form-group-content {
                    background-color: $background-general-more-info-button-dark;
                }
            }
        }
        &.white {
            .experience-form {
                .form-group-content {
                    background-color: $background-resume-white;
                }
            }
        }
    }
</style>