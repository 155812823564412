<template>
    <div id="line-resume-content">
        <div class="line-resume">
            <p class="title-line-resume">
                {{ experienceById(item.id_experience).name }} <br />
                {{ getDateSelected() }} a las {{ roomHour(item) }}
            </p>
            <div class="subtitle-line-resume">
                <p>- {{ getNumPlayerItems({id_experience: item.id_experience}) }} jugadores </p>
                <p>{{ getTotalPriceCalcutate }}€</p>
            </div>
            <div v-if="field.amount > 0" class="subtitle-line-resume" v-for="field in item.fields">
                <p>- {{ field.title }} : {{ field.value }}</p>
                <p>{{ field.amount }}€</p>
            </div>
            <div v-if="item.discount_amount > 0" class="resume-inline-discount">
                <p>Código {{ getCheckout().code }}</p>
                <p>- {{ getTotalDiscount }}€</p>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: "line-resume-content",
        computed: {
            ...mapGetters(['getCheckout', 'getExperiences', 'getDateSelected','getNumPlayerItems']),
            getTotalPriceCalcutate: function () {
                let total = 0;
                let totalPlayers = 0;
                this.getCheckout().items.map((item) => {
                    if(item.start) {
                        totalPlayers += item.people_number;
                        let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                        total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                    }
                });
                return parseFloat(total).toFixed(2);
            },
          getTotalDiscount: function () {
            let totalDiscount = 0;
            this.getCheckout().items.map((item) => {
                totalDiscount += item.discount_amount;
            });
            return parseFloat(totalDiscount).toFixed(2);
          },
            roomHour: function () {
                return (room) => {
                    return room.start.split(' ')[1].slice(0, -3);
                }
            },
            experienceById: function () {
                return (id_experience) => {
                    return this.getExperiences().filter((experience) => { return experience.id === id_experience })[0]
                }
            },
            item: function () {
                return this.getCheckout().items[0];
            },
            getNumItems: function () {
              return this.getCheckout().items.length;
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #aion-checkout {
        #line-resume-content {
            .line-resume {
                width: calc(100% - 12px);
                margin-left: 12px;
                .title-line-resume {
                    color: $primary-color-text-white;
                    text-transform: uppercase;
                    font-size: 22px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                .subtitle-line-resume {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 15px;
                    margin-left: 20px;
                    * {
                        color: $primary-color-text-white;
                    }
                    p {
                        text-transform: initial;
                        font-family: 'DIN NextLTPro Condensed', SansSerif;
                        font-size: 18px;
                        line-height: 22px;
                        span {
                            display: inline-block;
                            margin-left: 13px;
                        }
                    }
                }
                .resume-inline-discount {
                    margin-top: 25px;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    color: $green-color;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            #line-resume-content {
                .line-resume {
                    .title-line-resume {
                        font-size: 19px;
                        line-height: 20px;
                    }
                    .subtitle-line-resume,
                    .resume-inline-discount {
                        font-size: 16px;
                        margin-left: 0;
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
</style>