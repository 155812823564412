<template>
    <div id="header-experience">
        <div class="back-button" @click="$emit('back-button')">Volver</div>
        <div v-bind:style="{ background: 'url(' + experience.image + ') no-repeat center center' }" class="form-group-header">
            <p class="experience-name">{{ experience.name }}</p>
        </div>
        <div class="more-info-button">
            <more-info v-if="experience.description.length > 0"
                       :title="$t('informationButton')"
                       :description="'<p>' + experience.description + '</p>'"></more-info>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import MoreInfo from './../../mini-components/more-info';

    export default {
        name: "header-experience",
        components: { MoreInfo },
        data() {
            return {
                experience: null,
            }
        },
        created() {
            this.experience = this.getExperiencesCheckout()[0];
        },
        computed: {
            ...mapGetters(['getExperiencesCheckout']),
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #header-experience {
        margin-bottom: 20px;
        .back-button {
            margin-bottom: 15px;
            text-transform: uppercase;
            color: $yellow-color;
            cursor: pointer;
        }
        .form-group-header {
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 20px;
            background-size: cover !important;
            p {
                margin: 0;
            }
            .experience-name {
                font-size: 35px;
                line-height: 38px;
                margin: 20px 20px 0 0;
                text-transform: uppercase;
                padding-left: 15px;
                color: #fff;
                text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
            }
        }
    }

</style>