<template>
    <div id="date-selected">
        <div id="calendar-box" class="form-group date-selected" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
            <label for="">Fechas y horas disponibles</label>
            <div class="input-select" @click="openCalendar">
                {{ getDateSelected() }}
            </div>
            <div v-show="showCalendarBox" class="calendar-box">
                <Calendar @date-selected="dateSelected"
                          @date-selected-notfound="notAvailableDay"
                          @date-selected-click="dateSelectedClick"
                          :number-players="numberPlayers" :experience="experience" :block-view="blockView"/>
            </div>
        </div>

        <div id="schedules-box" class="form-group schedule-selected" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
            <div class="input-select" @click="showScheduleBox = (searchSchedule) ? false : !showScheduleBox">
                {{ getItemsExperience }}
            </div>
            <div v-show="showScheduleBox" class="schedule-box">
                <Schedules :key="keySchedules" :display-mode="displayMode" :number-players="numberPlayers" :experience="experience" @schedule-selected="scheduleSelected" @schedule-loaded="onScheduleLoaded"/>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import Calendar from './../../calendar/calendar.vue';
    import Schedules from './../../schedules/schedules.vue'

    export default {
        name: "date-selected",
        props: ["blockView","numberPlayers","experience","displayMode"],
        components: { Calendar, Schedules },
        data() {
            return {
                showCalendarBox: false,
                showScheduleBox: false,
                notAvailableDayShow: false,
                searchSchedule: false,
                keySchedules: 0
            }
        },
        computed: {
            ...mapGetters(['getDateSelected','getDesign','getCheckout']),
            getItemsExperience: function () {
                if (this.searchSchedule){
                  this.getCheckout().items[0].start = null;
                  return 'Buscando disponibilidad...';
                }
                else if(this.getCheckout().items[0].start) {
                    return this.getCheckout().items[0].start.split(' ')[1].slice(0, -3);
                }
                else{
                    return 'Seleccione una hora';
                }
            }
        },
        methods: {
            ...mapMutations(['updateDateSelected']),
            dateSelected: function (dateSelected) {
                this.updateDateSelected({dateSelected: dateSelected});
                this.keySchedules++;
            },
            dateSelectedClick: function (dateSelected) {
                this.showCalendarBox = false;
                this.showScheduleBox = false;
                this.searchSchedule = true;
                this.updateDateSelected({dateSelected: dateSelected});
                // document.getElementById("schedules-box").scrollIntoView(true);
                this.keySchedules++;
            },
            openCalendar: function() {
                this.showCalendarBox = !this.showCalendarBox
                document.getElementById("calendar-box").scrollIntoView(true);
            },
            notAvailableDay: function (notAvailableDay) {
                this.notAvailableDayShow = notAvailableDay;
            },
            scheduleSelected: function () {
                this.showScheduleBox = false;
            },
            onScheduleLoaded: function () {
                this.searchSchedule = false;
                this.showScheduleBox = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
 #date-selected {
     .date-selected, .schedule-selected {
         position: relative;
         .calendar-box, .schedule-box {
             margin-top: -8px;
             left: 0;
             width: 100%;
             background-color: #fff;
             padding: 20px;
             border-bottom-left-radius: 20px;
             border-bottom-right-radius: 20px;
         }
     }
 }
</style>