<template>
    <div id="aion-checkout" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div id="experience-selector" v-if="showExperiences">
            <ExperiencesSmall
                              :more-info="showMoreInfoExperience"
                              :show-experience-name="showExperienceName"
                              :show-room-name="showRoomName"
                              :multi-booking="multiBooking"
                              :call-sunday="callSunday"
                              :url-experience="urlInfoExperience"
                              :display-mode="displayMode"
                              @nex-step="nextStepOne"
            />
            <MoreInfo :title="$t('generalInformation')" v-if="showMoreInfoGeneral && moreInfoText.length > 0" :description="moreInfoText" />
        </div>

        <div id="calendar-selector" v-if="showDateSelection">
            <CalendarSelector :block-view="blockView" :display-mode="displayMode" @nex-step="nextStepTwo"/>
        </div>

        <div id="checkout-final" v-if="showCheckout">
            <Checkout @back-step="backStepCheckout" :terms="terms"/>
        </div>

        <div v-if="showDateSelection && getTotalPriceCalcutate > 0" class="form-group-price">
            <div class="price-number">
                Precio:
                <span>{{ getTotalPriceCalcutate }}€</span>
            </div>
            <div class="button button-flat" v-if="getCheckout().items[0].start" @click="nextStep">Siguiente</div>
        </div>
      <div class="powered-by">powered by <a href="https://proescape.es/servicios/sistema-gestor-de-reservas/">AION</a></div><!-- https://proescape.es/servicios/sistema-gestor-de-reservas/ -->
    </div>
</template>

<script>
    import ExperiencesSmall from './components/experiences/experiences-small.vue';
    import MoreInfo from './components/mini-components/more-info.vue'
    import CalendarSelector from './components/calendar-selector/CalendarSelector.vue'
    import Checkout from './components/checkout/checkout.vue'
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import api from '@/api';

    export default {
        extends: api,
        name: "checkout",
        components: {
            ExperiencesSmall,
            MoreInfo,
            CalendarSelector,
            Checkout
        },
        data() {
            return {

                showExperiences: false,
                showDateSelection: false,
                showCheckout: false,

                multiBooking: false,
                showMoreInfoGeneral: false,
                showRoomName: null,
                showExperienceName: false,
                showMoreInfoExperience: false,
                urlInfoExperience: null,
                moreInfoText: '',
                callSunday: false,
                displayMode: '',
                blockView: 'default',
                terms: ''

            }
        },
        computed: {
            ...mapGetters(['getToken','getMinHeight','getDesign','getExperiences','getIdPurchase','getCheckout']),
            getTotalPriceCalcutate: function () {
                let total = 0;
                let totalPlayers = 0;
                this.getCheckout().items.map((item) => {
                    if(item.start) {
                        totalPlayers += item.people_number;
                        let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                        total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                    }
                });
                return parseFloat(total).toFixed(2);
            },
        },
        created() {
            this.callGetToken();
            this.checkLocalStorage();
        },
        methods: {
            ...mapMutations(['updateToken','updateDesign','updateIds','updateIdsLinks','updatePaymentsMethods','createDateSelected']),
            callGetToken: function () {
                let tokenPromise = new Promise((resolve) => {
                    this.getTokenApi(resolve);
                });
                tokenPromise.then((token) => {
                    this.updateToken({token: token});
                    this.setCurrentDate();
                    this.callGetIds();
                });
            },
            callGetIds: function () {
                let idsPromise = new Promise((resolveOther) => {
                    this.getIdsApi(resolveOther);
                });
                idsPromise.then((integration) => {

                    this.multiBooking = integration.multibooking;
                    this.showMoreInfoGeneral = integration.info;
                    this.showRoomName = integration.line;
                    this.showExperienceName = integration.calname;
                    this.showMoreInfoExperience = integration.moreinfo;
                    this.urlInfoExperience = JSON.parse(integration.urls);
                    this.moreInfoText = integration.text ? integration.text : '';
                    this.callSunday = integration.sundayCall;
                    this.displayMode = integration.displayMode;
                    this.blockView = integration.blockview;
                    this.terms = integration.terms;

                    this.updateIds({ids: integration.ids});
                    this.updateDesign({design: integration.design});
                    this.updateIdsLinks({idsLink: integration.urls});
                    this.updatePaymentsMethods({paymentMethods: integration.payments});

                    this.showExperiences = true;
                });
            },
            setCurrentDate: function () {
                let date = new Date();
                let dateSelectedInitial =  date.getDate() + '/' +  (date.getMonth() + 1 ) + '/' +  date.getFullYear();
                this.createDateSelected({dateSelected: dateSelectedInitial});
            },
            nextStepOne: function () {
                this.showExperiences = false;
                this.showDateSelection = true;
            },
            nextStepTwo: function () {
                this.showDateSelection = false;
                this.showCheckout = true;
            },
            nextStep: function () {
                this.showExperiences = false;
                this.showDateSelection = false;
                this.showCheckout = true;
            },
            backStepCheckout: function () {
                this.showExperiences = false;
                this.showDateSelection = true;
                this.showCheckout = false;
            },
            checkLocalStorage() {
                setTimeout(() => {
                    let idPurchase = localStorage.getItem('toPayment');
                    if(idPurchase) {
                        //this.refreshAndCancelFromPayment(idPurchase, localStorage.getItem('token'));
                        localStorage.removeItem('toPayment');
                        localStorage.removeItem('token');
                    }
                    else {
                        this.checkLocalStorage();
                    }
                }, 1000)
            },
            refreshAndCancelFromPayment: function (idPurchase, token) {
                let cancelPromise = new Promise((resolve) => {
                    this.cancelPurchaseById(resolve,idPurchase, token);
                });
                cancelPromise.then(() => {
                    this.refreshPurchase();
                    document.getElementById("aion-checkout").scrollIntoView();
                });

            },
            refreshPurchase: function () {

                this.refreshState();

                this.showExperiences = false;
                this.showDateSelection = false;
                this.showCheckout = false;
                this.showDateSelection = false;
            },
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "@/scss/_general.scss";

    body {
        background-color: #000;
    }

    #aion-checkout {
        width: 100%;
        max-width: 414px;
        overflow: hidden;
        font-family: 'Alternate Gothic NO2 BT', SansSerif;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 20px;
        padding: 20px;
        border-radius: 20px;
        * {
            box-sizing: border-box;
        }
        .form-group-price {
            padding: 15px 30px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: $background-general-more-info-button-white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price-number {
                color: #fff;
                text-transform: uppercase;
                span {
                    font-size: 35px;
                    line-height: 38px;
                }
            }
            .button-flat {
                width: 130px;
            }
        }
        .powered-by {
            text-align: right;
            font-size: 14px;
            //margin-right: -5px;
            color: #5e686c;
            a {
                text-decoration: none;
                color: #fec02f;
            }
        }
        &.dark {
            background-color: $background-dark;
        }
        &.white {
            background-color: $background-white;
        }
    }
</style>
