class ItemCheckout {

    constructor(id_experience, id_calendario, start, end, people_number, price_original, price_fractioned) {
        this.id_experience = id_experience,
        this.id_calendario = id_calendario,
        this.start = start,
        this.end = end,
        this.people_number = people_number,
        this.price_original = price_original,
        this.price_fractioned = price_fractioned,
        this.fields = []
    }
}

export default ItemCheckout;