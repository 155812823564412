<template>
    <div id="experience" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div class="background-box" @click="selectSchedule" v-bind:style="{ background: 'url(' + this.experienceContent.image + ') no-repeat top center' }">
            <div class="header-box" style="margin-bottom: 10px;">
                <p v-if="showExperienceName" class="experience-name">{{ this.experienceContent.name }}</p>
                <div  class="button-flat button-flat-green" style="letter-spacing: 2px;margin-top: 10px;">RESERVAR</div>
            </div>
            <div class="background-box-icons">
                <div class="icon">
                    <img src="./assets/people.png" alt="">
                    {{ this.experienceContent.minPlayers }} - {{ this.experienceContent.maxPlayers * this.getNumberCalendars }} {{ $t('people') }}
                </div>
                <div class="icon">
                    <img src="./assets/time_info.png" alt="">
                    {{ this.experienceContent.duration * this.experienceContent.sessionMultiplier }} {{ $t('minutes') }}
                </div>
                <div class="icon">
                    <img :src="require('./assets/older.png')" alt="">
                    {{ this.experienceContent.ageinfo }}
                </div>
                <div class="icon">
                    <img :src="require('./assets/location.png')" alt="">
                    {{ this.experienceContent.location }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ItemCheckout from './../../../classes/ItemCheckOut';

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        props: ["experienceContent","displayMode","moreInfo","multiBooking","urlExperience","showExperienceName","showRoomName","callSunday"],
        name: "experience",
        computed: {
            ...mapGetters(['getDesign','getDateSelected']),
            getPrice: function () {
                return this.experienceContent.prices.prices[0].full_price;
            },
            getCalendar: function () {
                return this.experienceContent.calendars[0].id;
            },
            getNumberCalendars: function () {
              return this.experienceContent.calendars.length;
            }
        },
        methods: {
            ...mapMutations(['addItem']),
            selectSchedule: function () {

                let itemCheckout = new ItemCheckout(
                    this.experienceContent.id,
                    this.getCalendar,
                    null,
                    null,
                    this.experienceContent.prices.prices[0].people,
                    this.experienceContent.prices.prices[0].full_price,
                    this.experienceContent.prices.partial_price,
                );

                this.addItem({item: itemCheckout});

                this.$emit('nex-step');
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #experience {
        margin-bottom: 10px;
        .background-box {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            background-size: cover !important;
            border-radius: 23px;
            cursor: pointer;
            .header-box {
                margin: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                .experience-name {
                    font-size: 35px;
                    line-height: 38px;
                    text-transform: uppercase;
                    padding-left: 25px;
                    margin: 0;
                    text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
                }
                .button-flat-green {
                    cursor: pointer;
                    background-color: #30a64a;
                    padding: 0 10px;
                    border-radius: 10px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                }
            }

            * {
                color: #fff;
            }
            a {
                position: absolute;
                top: 15px;
                right: 15px;
                text-decoration: none;
            }
            .background-box-icons {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 15px 25px;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                .icon {
                    flex-basis: 25%;
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 12px;
                    line-height: 12px;
                    img {
                        height: 22px;
                        margin-bottom: 8px;
                        width: auto;
                    }
                }
            }
        }
        &.dark {
            .background-box-icons {
                background-color: $background-resume-dark;
            }
        }
        &.white {
            .background-box-icons {
                background-color: $background-general-more-info-button-white;
            }
        }
    }
</style>