<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label for="">{{ this.field.name }}  <span v-if="field.isRequired">*</span></label>
        <textarea :required="field.isRequired" v-model="value" name="" id="" cols="30" rows="10" @change="changeInput"></textarea>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "textarea-normal",
        props: ["field","experience"],
        data() {
            return {
                value: '',
            }
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout','getFieldItems'])
        },
        created() {

            if(this.field.value) {
                this.value = this.field.value;
            }

            let fieldTemp = this.getFieldItems({id_experience: this.experience, title: this.field.name});
            if(fieldTemp) {
                this.value = fieldTemp.value;
            }

            this.changeInput();
        },
        methods: {
            ...mapMutations(['updateItemField']),
            changeInput: function () {

                let newField =  {
                    id: this.$uuid.v4(this.field.id),
                    name: this.field.name,
                    title: this.field.name,
                    value: this.value,
                    amount: 0
                };
                this.updateItemField({id_experience: this.experience, newField: newField});

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            }
        }
    }
</script>

<style scoped>

</style>