<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label>{{ this.field.name }}</label>
        <select v-model="idSelectedField" :required="field.isRequired" @change="changeField">
            <option :value="index" v-for="(option, index) in this.field.options">{{ changeNameLanguage(option.name) }}</option>
        </select>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "select",
        props: ["field","experience","room"],
        data() {
            return {
                idSelectedField: 0,
            }
        },
        created() {
            this.changeField();
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout']),
            changeNameLanguage: function () {
                return (name) => {
                    if(name === 'Spanish') return 'Español';
                    else if(name === 'English (United States)') return 'English';
                    else return name;
                }
            }
        },
        methods: {
            ...mapMutations(['updateCheckoutField']),
            changeField() {

                let value = this.field.options[this.idSelectedField];
                this.updateCheckoutField({name: 'language', value: value.value});

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            }
        }
    }
</script>