import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    props: ["callSunday"],
    data() {
        return {
            keyPaymentMethod: 0,
            optionPaymentSelected: 1,
            labelSend: this.$t('sendReserve'),
            paymentMethods: [],
            paymentMethodSelected: null

        }
    },
    computed: {
        ...mapGetters(['getState','getToken','getDesign','getCheckout','getExperiences','getExperiencesCheckout','getPaymentMethods','getDateSelected']),
        getTotalPriceCalcutate: function () {
            let total = 0;
            let totalPlayers = 0;
            this.getCheckout().items.map((item) => {
                if(item.start) {
                    totalPlayers += item.people_number;
                    let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                    total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                }

            });
            return parseFloat(total).toFixed(2);
        },
        getFractionedPriceCalcutate: function () {
            let total = 0;
            this.getCheckout().items.map((item) => {
                total += item.price_fractioned;
            });
            return parseFloat(total).toFixed(2);
        },
        isPartialPrice: function () {
            let isPartialPrice = [];
            this.getExperiencesCheckout().map((experience) => {
                isPartialPrice.push(experience.prices.partial_price);
            });
            return isPartialPrice.some((value) => value !== 0);
        },
        isForcePartial: function () {

            let isForcePartialComplete = [];
            this.getExperiencesCheckout().map((experience) => {
                isForcePartialComplete.push(experience.prices.force_partial);
            });

            let all =  isForcePartialComplete.every((value) => value == true);

            if(all) {
                this.optionPaymentSelected = 2;
                this.changeTotalToPaid()
            }
            return all;
        }
    },
    watch: {
        optionPaymentSelected: function () {
            this.changeTotalToPaid()
        },
    },
    methods:  {
        ...mapMutations(['refreshState','updateCheckoutField','updateCheckout','updateCheckoutPartial']),
        changePaymentMethod: function (value) {
            this.paymentMethodSelected = value;
        },
        finishPurchase: function () {
            let updatePurchasePromise = new Promise((resolve) => {
                this.updatePurchaseApi(resolve);
            });
            updatePurchasePromise.then((purchase) => {
                this.disablePopUpClose();
                let preparePaymentPromise = new Promise((resolve) => {
                    this.preparePaymentMethodApi(this.paymentMethodSelected.paymentMethodId, resolve);
                });
                preparePaymentPromise.then((payment) => {
                    this.$emit('change-payment-method', {payment: payment, paymentMethodId: this.paymentMethodSelected.paymentMethodId});
                    document.getElementById('submit-purchase').click();
                });
            });
        },
        copyClipboard: function () {

            let idPurchase = this.getCheckout().id;

            if(idPurchase) {
                this.labelSend = this.$t('clicSendDone');

                //console.log(window.location.href + '?checkout=' + idPurchase + '&dateSelected=' + this.getDateSelected());

                navigator.clipboard.writeText(window.location.href + '?checkout=' + idPurchase + '&dateSelected=' + this.getDateSelected());
            }
            else {
                this.labelSend = this.$t('notReservedDone');
            }

        },
        changeTotalToPaid: function () {
            this.updateCheckoutPartial({is_fractioned: this.optionPaymentSelected == 2});
        },
        disablePopUpClose: function () {
            window.onbeforeunload = function () {};
            window.unload = function () {};
        },
    }
}